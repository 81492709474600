<template>
  <v-form
    ref="form"
    @submit.prevent="changePassword"
  >
    <v-alert
      v-for="error in errorMessages"
      :key="error"
      type="error"
    >
      {{ error }}
    </v-alert>
    <v-card :loading="submitting">
      <v-card-title>
        Change Password
      </v-card-title>
      <v-card-text v-if="!success">
        <p>Confirm your old password and enter your new password twice to change.</p>
        <v-text-field
          v-model="credentials.old_password"
          :disabled="submitting"
          :rules="oldPasswordRules"
          required
          type="password"
          label="Old Password"
        />
        <v-text-field
          v-model="credentials.password1"
          :disabled="submitting"
          :rules="passwordRules"
          required
          type="password"
          label="New Password"
        />
        <v-text-field
          v-model="credentials.password2"
          :disabled="submitting"
          :rules="passwordMatchRules"
          required
          type="password"
          label="Repeat New Password"
        />
      </v-card-text>
      <v-card-actions v-if="!success">
        <v-btn
          color="primary"
          :disabled="submitting"
          type="submit"
        >
          Submit
        </v-btn>
      </v-card-actions>
      <v-card-text v-if="success">
        <p>Password successfully changed. You may now login using your new password.</p>
      </v-card-text>
    </v-card>
  </v-form>
</template>

<script>
import { allianceAPI } from '@/api'
import { parseResponseErrors } from '@/utils/errors'
import { passwordRules } from '@/utils/rules'

export default {
  data() {
    return {
      credentials: {
        old_password : '',
        password1 : '',
        password2 : '',
      },
      submitting: false,
      success: null,
      errorMessages: null,
      passwordRules: passwordRules,
      oldPasswordRules: [
        v => !!v || 'Field is required',
      ],
      passwordMatchRules: [
        v => !!v || 'Field is required',
        v => (!!v && v) === this.credentials.password1 || 'Passwords do not match'
      ],
    }
  },
  methods: {
    changePassword: function () {
      if (!this.$refs.form.validate()) {
        return
      }
      this.submitting = true
      this.success = null
      this.errorMessages = null
      const data = {
        old_password: this.credentials.old_password,
        new_password1: this.credentials.password1,
        new_password2: this.credentials.password2,
      }
      return allianceAPI.post('/auth/password/change/', data)
        .then(() => {
          this.success = true
          this.credentials = {
            old_password : '',
            password1 : '',
            password2 : '',
          }
          // Logout the user.
          this.$store.dispatch('auth/logout')
        })
        .catch((error) => {
          this.errorMessages = parseResponseErrors(error)
          this.success = false
        })
        .finally(() => this.submitting = false)
    }
  },
}
</script>
